import { SagaIterator } from "redux-saga";
import {
  call, fork, put, select, take, takeEvery, takeLatest,
} from "redux-saga/effects";
import { endOfDay, format, formatISO, startOfDay, subDays } from "date-fns";

import NotificationManager from "../../services/notifications";
import apiService, { ApiResponse } from "../../services/api";
import { Dialog, DialogsHistorySearchFilter, ResponseMessage } from "../../types/models/Dialog";
import { NewSampling, Sampling } from "../../types/models/Sampling";
import { parseNewSampling, parseSampling } from "../../types/parsers/SamplingParser";
import {
  CreateSamplingNewRequest,
  CreateSamplingNewResponse,
  CreateSamplingRequest,
  CreateSamplingsResponse,
  DeleteSamplingNewRequest,
  DeleteSamplingRequest,
  GetSamplingsNewResponse,
  GetSamplingsResponse,
  GetSapmlingsNewRequest,
  GetSapmlingsRequest,
} from "../../types/requests/Samplings";
import { getDialogHistorySearchFilter, getNewDialogHistorySearchFilter } from "../dialogs/selectors";
import { getActiveDialogMeta, getActiveDialogToMark, getActiveSampling, getActiveSamplingDialogs, getIsActiveDialogLast, getNewSamplingById, getResolutionById, getSamplingBySlug } from "./selectors";

import * as actions from './actions';
import * as dialogsActions from '../dialogs/actions';
import * as filesActions from '../files/actions';
import navigationService from "../../services/navigation";
import authorizedPages from "../../routes/authorized";
import { deepClone, getEnvBoolean, objSnakeToCamelDeep, onlyUnique, wrapErrorMessage } from "../../services/helpers/utilities";
import i18n from "../../services/i18n";
import { ProjectRequestParams } from "../../types/requests/Projects";
import { getGeneralRequestParams } from "../selectors";
import { ErrorAction } from "../reducers";

import { GetChatInfoRequest, GetChatInfoResponse, SearchDialogsHistoryRequest, SearchDialogsHistoryResponse } from "../../types/requests/Dialogs";
import { parseNewDialog } from "../../types/parsers/DialogParser";
import { msToSeconds } from "../projects/helpers";
import { MessageAuthor } from "../../types/models/Message";
import { getAudioFiles } from "../files/selectors";
import { fillMacrosesNewDialogs, findMacrosesInNewDialog, getMacrosesTexts, parseAudioMessagesToFullComment } from "../dialogs/helpers";
import { isVoiceProject, Project } from "../../types/models/Project";
import { getSelectedProject } from "../projects/selectors";
import { defaultFilters } from "../dialogs/reducer";
import { dateLocalToMsc } from "../../services/helpers/dateUtils";
import { ActiveDialog } from "./reducer";
import { FilesState } from "../files/reducer";
import { parseDialogStatusParams } from "../dialogs/sagas";
import { GetProjectResolutionsResponse, GetQualityStatsByDayRequest, GetQualityStatsByDayResponse, GetQualityStatsBySamplingRequest, GetQualityStatsBySamplingResponse } from "../../types/requests/QualityStats";
import { ResolutionType } from "../../types/models/Resolution";

import { mockResolutionTypes } from "./mocks";
import { ResolutionStatsItem, SamplingStats, StatsByDayPlotItem, getTotalMarkedCount } from "../../types/models/QualityStats";
import { CreateResolutionRequest, UpdateResolutionRequest } from "../../types/requests/Resolution";
import { ResolutionBackend } from "../../types/backendModels/ResolutionBackend";
import { parseFiltersToBackend } from "../../types/parsers/FilterParser";
import { getScenarioGraphs } from "../scenarioGraphs/selectors";
import { UseSamplingIdConfig } from "../../types/codegen/experiments";
import { getConfig } from "../user/selectors";

const CHUNK_SIZE = 10;

function* loadSamplings(): SagaIterator {
  yield takeLatest(actions.loadSamplings.request, function* () {
    try {
      const general: ProjectRequestParams = yield select(getGeneralRequestParams);
      const request: GetSapmlingsRequest = general;

      const response: ApiResponse<GetSamplingsResponse> = yield call(apiService.getSamplings, request);
      const parsedSamplings = response.data.samplings.map(sampling => parseSampling(sampling));
      yield put(actions.loadSamplings.success(parsedSamplings));
    } catch (err) {
      yield put(actions.loadSamplings.failure(new ErrorAction(
        err,
        i18n.t("ERRORS.API.SAMPLINGS.LOAD"),
      )));
    }
  });
}

function* loadNewSamplings(): SagaIterator {
  yield takeLatest(actions.loadNewSamplings.request, function* () {
    try {
      const general: ProjectRequestParams = yield select(getGeneralRequestParams);
      const request: GetSapmlingsNewRequest = general;

      const response: ApiResponse<GetSamplingsNewResponse> = yield call(apiService.getSamplingsNew, request);
      const parsedSamplings = response.data.samplings.map(sampling => parseNewSampling(sampling));
      yield put(actions.loadNewSamplings.success(parsedSamplings));
    } catch (err) {
      yield put(actions.loadNewSamplings.failure(new ErrorAction(
        err,
        i18n.t("ERRORS.API.SAMPLINGS.LOAD"),
      )));
    }
  });
}

function* createSampling() {
  yield takeLatest(actions.createSampling.request, function* (action) {
    try {
      const general: ProjectRequestParams = yield select(getGeneralRequestParams);

      const { filters, name, quantity } = action.payload;

      const request: CreateSamplingRequest = {
        ...general,
        name,
        quantity,
        filters: {
          //old
          ...(filters.dateFrom && {
            start: msToSeconds(+dateLocalToMsc(new Date(filters.dateFrom))),
          }),
          ...(filters.dateTo && {
            end: msToSeconds(+dateLocalToMsc(new Date(filters.dateTo))),
          }),
          ...(typeof filters.mode === 'boolean' && { simulated: filters.mode }),
          ...(Array.isArray(filters.tags) && filters.tags.length > 0 && {
            tags: filters.tags,
          }),
          ...(Array.isArray(filters.scenarios) && filters.scenarios.length > 0 && {
            scenario_slugs: filters.scenarios.map(e => e.slug),
          }),
          ...parseDialogStatusParams(filters.status),
        },
      };

      const response: ApiResponse<CreateSamplingsResponse> = yield call(
        apiService.createSampling,
        request,
      );
      const parsedSampling = parseSampling(response.data);

      const currentFilters: DialogsHistorySearchFilter = yield select(getDialogHistorySearchFilter);
      yield put(dialogsActions.setDialogsHistoryFilter({
        ...currentFilters,
        samplingDeprecated: parsedSampling,
      }));

      yield put(actions.createSampling.success(parsedSampling));
    } catch (err) {
      yield put(actions.createSampling.failure(new ErrorAction(
        err,
        i18n.t("ERRORS.API.SAMPLINGS.CREATE"),
      )));
    }
  });
}

function* createNewSampling() {
  yield takeLatest(actions.createNewSampling.request, function* (action) {
    try {
      const general: ProjectRequestParams = yield select(getGeneralRequestParams);

      const { filters, name, quantity } = action.payload;

      const request: CreateSamplingNewRequest = {
        project_slug: general.project_slug,
        title: name,
        max_size: quantity,
        filters: {
          start_dt: (dateLocalToMsc(new Date(filters.dateFrom))).toISOString(),
          ...(filters.dateTo && { end_dt: (dateLocalToMsc(new Date(filters.dateTo))).toISOString() }),
          ...(typeof filters.mode === 'boolean' && { simulated: filters.mode }),
          ...(filters.chatUser && { chat_user: filters.chatUser }),
          ...(filters.version && { version: filters.version }),
          ...(filters.tags?.length && { tags: filters.tags }),
          ...(filters.searchText && { search_text: filters.searchText }),
          ...(Array.isArray(filters.scenarios) && filters.scenarios.length > 0 && {
            scenarios: filters.scenarios.map(s => s.slug),
          }),
          ...parseDialogStatusParams(filters.status),
          ...(filters.minUserScore && { min_csat: filters.minUserScore }),
          ...(filters.maxUserScore && { max_csat: filters.maxUserScore }),
          ...(filters.chatIds && { chat_ids: filters.chatIds }),
          ...(filters.iterations && {
            min_iteration: Number(filters.iterations),
            max_iteration: Number(filters.iterations),
          }),
        },
      };

      const response: ApiResponse<CreateSamplingNewResponse> = yield call(
        apiService.createSamplingNew,
        request,
      );
      const parsedSampling = parseNewSampling(response.data);

      const currentFilters: DialogsHistorySearchFilter = yield select(getNewDialogHistorySearchFilter);
      yield put(dialogsActions.setSearchDialogsHistoryFilter({
        ...currentFilters,
        newSampling: parsedSampling,
      }));

      yield put(actions.createNewSampling.success(parsedSampling));
    } catch (err) {
      yield put(actions.createNewSampling.failure(new ErrorAction(
        err,
        i18n.t("ERRORS.API.SAMPLINGS.CREATE"),
      )));
    }
  });
}

function* markSampling(): SagaIterator {
  yield takeLatest(actions.navigateMarkSampling, function* (action) {
    try {
      const sampling: Sampling = yield select(getSamplingBySlug, action.payload);
      if (!sampling) {
        throw new Error(i18n.t("ERRORS.API.SAMPLINGS.CANT_IDENTIFY"));
      }

      const dialogHistorySearchFilter: DialogsHistorySearchFilter = yield select(getDialogHistorySearchFilter);

      yield put(dialogsActions.setDialogsHistoryFilter({
        ...dialogHistorySearchFilter,
        ...defaultFilters,
        samplingDeprecated: sampling,
        resolution: null,
      }));

      navigationService.navigateTo(authorizedPages.newDialogsHistory);
    } catch (err) {
      NotificationManager.error(wrapErrorMessage(err, i18n.t("ERRORS.API.SAMPLINGS.CANT_NAVIGATE_MARK")));
    }
  });
}

function* deleteSampling(): SagaIterator {
  yield takeLatest(actions.deleteSampling.request, function* (action) {
    try {
      const samplingSlug = action.payload;

      const request: DeleteSamplingRequest = {
        query: {
          sampling_slug: samplingSlug,
        },
      };

      yield call(
        apiService.deleteSampling,
        request,
      );

      yield put(actions.deleteSampling.success(samplingSlug));
      yield put(actions.loadSamplings.request());
    } catch (err) {
      const text = i18n.t("ERRORS.API.SAMPLINGS.DELETE");
      yield put(actions.deleteSampling.failure(new ErrorAction(err, text)));
    }
  });
}

function* deleteNewSampling(): SagaIterator {
  yield takeLatest(actions.deleteNewSampling.request, function* (action) {
    try {
      const samplingId = action.payload;

      const request: DeleteSamplingNewRequest = {
        path: {
          sampling_id: samplingId,
        },
      };

      yield call(
        apiService.deleteSamplingNew,
        request,
      );

      yield put(actions.deleteNewSampling.success(samplingId));
    } catch (err) {
      const text = i18n.t("ERRORS.API.SAMPLINGS.DELETE");
      yield put(actions.deleteNewSampling.failure(new ErrorAction(err, text)));
    }
  });
}

function* markDialog(): SagaIterator {
  yield takeLatest(actions.markDialog.request, function* (action) {
    try {
      const {
        resolution: resolutionId,
        badMessageSaiId,
      } = action.payload;
      if (!resolutionId) throw new Error("Missing status");

      const activeDialog: Dialog | undefined = yield select(getActiveDialogToMark);
      if (!activeDialog) throw new Error("Missing dialog");

      const selectedProject: Project = yield select(getSelectedProject);

      let targetMessage: ResponseMessage | undefined = undefined;
      if (badMessageSaiId) {
        targetMessage = activeDialog.messages
          .find(m => (m as ResponseMessage).saiRequestId === badMessageSaiId) as ResponseMessage;
      } else {
        const reversedMessages = deepClone(activeDialog.messages).reverse();
        targetMessage = reversedMessages.find(m => (
          m.author !== MessageAuthor.User
        )) as ResponseMessage;
      }

      if (!targetMessage) {
        throw new Error("Missing message");
      }

      const resolution: ResolutionBackend = {
        project_slug: selectedProject.slug,
        session_id: activeDialog.sessionId,
        sai_request_id: targetMessage.saiRequestId as string,
        resolution_type_id: resolutionId,
        dialog_created_at: formatISO(dateLocalToMsc(new Date(activeDialog.createdAt))),
      };

      if (activeDialog.resolution?.value) {
        const request: UpdateResolutionRequest = {
          query: {
            session_id: activeDialog.sessionId,
          },
          body: resolution,
        };

        yield call(apiService.updateResolution, request);
      } else {
        const request: CreateResolutionRequest = {
          body: resolution,
        };

        yield call(apiService.createResolution, request);
      }

      yield put(actions.markDialog.success({
        resolution: resolutionId,
        badMessageId: targetMessage.id,
      }));

      const isLast: boolean = yield select(getIsActiveDialogLast);
      if (!isLast) {
        yield put(actions.openNextDialogToMark.request());
      }
    } catch (err) {
      yield put(actions.markDialog.failure(new ErrorAction(err, String(err))));
    }
  });
}

function* createAutomaticSampling(): SagaIterator {
  yield takeLatest(actions.createAutomaticSampling.request, function* () {
    try {
      const selectedProject: Project = yield select(getSelectedProject);

      const now = new Date();

      const request: CreateSamplingNewRequest = {
        title: i18n.t("PAGE_QUALITY_CONTROL.AUTOMATIC_SAMPLING"),
        project_slug: selectedProject.slug,
        filters: {
          start_dt: (dateLocalToMsc(subDays(now, 1))).toISOString(),
        },
        max_size: 100,
      };

      const response: ApiResponse<CreateSamplingNewResponse> = yield call(
        apiService.createSamplingNew,
        request,
      );

      const newSampling = parseNewSampling(response.data);

      yield put(actions.createAutomaticSampling.success(newSampling));
      yield put(actions.loadQualityStatsBySamplings.request({}));
    } catch (err) {
      const text = i18n.t("ERRORS.API.SAMPLINGS.CREATE");
      yield put(actions.createAutomaticSampling.failure(new ErrorAction(err, text)));
    }
  });
}

function* navigateToSamplingDialogs() {
  yield takeLatest(actions.navigateToSamplingDialogs, function* (action) {
    try {
      const { samplingId, resolutionId } = action.payload;

      const sampling: NewSampling = yield select(getNewSamplingById, samplingId);
      const resolution: ResolutionType = yield select(getResolutionById, resolutionId || "");

      const {
        scenarios = [],
        ...samplingFilters
      } = sampling.filters;

      const projectScenarios: DialogsHistorySearchFilter['scenarios'] = yield select(getScenarioGraphs);
      const samplingScenarios = projectScenarios.filter(s => (scenarios as unknown as string[]).includes(s.slug));

      yield put(dialogsActions.setSearchDialogsHistoryFilter({
        ...defaultFilters,
        ...samplingFilters,
        scenarios: samplingScenarios,
        ...(resolution && { resolution: resolution.id }),
        load: false,
      }));

      window.open(authorizedPages.dialogsHistory, "_blank");
    } catch (err) {
      NotificationManager.error(String(err));
    }
  });
}

function* openMarkingModal(): SagaIterator {
  yield takeLatest(actions.openMarkingModal.request, function* (action) {
    try {
      const { samplingId, startIndex } = action.payload;

      const sampling: NewSampling = yield select(getNewSamplingById, samplingId);

      const useSamplingIdConfig: UseSamplingIdConfig = yield select(getConfig, "supportai-frontend:use-sampling-id");

      const offset = Math.max(
        0,
        startIndex - 1,
      );

      const request: SearchDialogsHistoryRequest = {
        query: {
          limit: CHUNK_SIZE,
          offset,
        },
        body: useSamplingIdConfig.enabled ?
          { sampling_id: Number(sampling.id) } :
          parseFiltersToBackend(sampling.filters),
      };

      const response: ApiResponse<SearchDialogsHistoryResponse> = yield call(
        apiService.getDialogsHistoryV3,
        request,
      );

      let dialogs: Dialog[] = response.data.dialogs.map(parseNewDialog);

      const localIndex = startIndex - offset;

      let activeDialog = dialogs[localIndex];
      let activeDialogOriginalIndex = startIndex;

      if (!activeDialog) {
        activeDialog = dialogs[0];
        activeDialogOriginalIndex = offset;
      }

      yield put(actions.openMarkingModal.success({
        dialogs,
        activeDialog: {
          originalIndex: activeDialogOriginalIndex,
          id: activeDialog.id,
        },
      }));
    } catch (err) {
      const text = i18n.t("ERRORS.API.SAMPLINGS.LOAD");
      yield put(actions.openMarkingModal.failure(new ErrorAction(err, text)));
    }
  });
}

function* openNextDialogToMark(): SagaIterator {
  yield takeLatest(actions.openNextDialogToMark.request, function* () {
    try {
      const activeDialog: ActiveDialog = yield select(getActiveDialogMeta);
      const activeSampling: NewSampling = yield select(getActiveSampling);
      const dialogs: Dialog[] = yield select(getActiveSamplingDialogs);

      const localIndex = dialogs.findIndex(d => d.id === activeDialog.id);

      const isLast = dialogs.length - localIndex === 1;

      let nextDialogs: Dialog[] | undefined = undefined;
      let nextDialog: ActiveDialog = activeDialog;

      if (isLast) {
        const { originalIndex } = activeDialog;
        const request: SearchDialogsHistoryRequest = {
          query: {
            limit: CHUNK_SIZE,
            offset: originalIndex + 1,
          },
          body: parseFiltersToBackend(activeSampling.filters),
        };

        const response: ApiResponse<SearchDialogsHistoryResponse> = yield call(
          apiService.getDialogsHistoryV3,
          request,
        );

        const nextDialogsOld = response.data.dialogs.map(parseNewDialog);
        if (nextDialogsOld.length === 0) {
          throw new Error("No new dialogs");
        }
        nextDialogs = (yield call(getNewDialogsByIds, nextDialogsOld.map(d => d.id))) as Dialog[];

        const macroses = nextDialogs.map(findMacrosesInNewDialog)
          .flat()
          .filter(onlyUnique);

        if (macroses.length > 0) {
          const macrosesTexts: Array<{
            macros: string,
            text: string,
          }> = yield call(getMacrosesTexts, macroses);

          nextDialogs = nextDialogs.map(dialog => fillMacrosesNewDialogs(dialog, macrosesTexts));
        }

        const audioFiles: FilesState['audioFiles'] = yield select(getAudioFiles);
        nextDialogs = parseAudioMessagesToFullComment(nextDialogs, audioFiles);

        nextDialog = {
          id: nextDialogs[0].id,
          originalIndex: activeDialog.originalIndex + 1,
        };
      } else {
        nextDialog = {
          id: dialogs[localIndex + 1].id,
          originalIndex: activeDialog.originalIndex + 1,
        };
      }

      yield put(actions.openNextDialogToMark.success({
        nextDialogs,
        activeDialog: nextDialog,
      }));
    } catch (err) {
      yield put(actions.openNextDialogToMark.failure(new ErrorAction(err)));
    }
  });
}

function* openPrevDialogToMark(): SagaIterator {
  yield takeLatest(actions.openPrevDialogToMark.request, function* () {
    try {
      const activeDialog: ActiveDialog = yield select(getActiveDialogMeta);
      const activeSampling: NewSampling = yield select(getActiveSampling);
      const dialogs: Dialog[] = yield select(getActiveSamplingDialogs);

      const localIndex = dialogs.findIndex(d => d.id === activeDialog.id);

      const isFirst = localIndex === 0;

      let prevDialogs: Dialog[] | undefined = undefined;
      let prevDialog: ActiveDialog = activeDialog;

      if (isFirst) {
        const { originalIndex } = activeDialog;
        const request: SearchDialogsHistoryRequest = {
          query: {
            limit: CHUNK_SIZE,
            offset: Math.max(
              0,
              originalIndex - CHUNK_SIZE,
            ),
          },
          body: parseFiltersToBackend(activeSampling.filters),
        };

        const response: ApiResponse<SearchDialogsHistoryResponse> = yield call(
          apiService.getDialogsHistoryV3,
          request,
        );

        prevDialogs = response.data.dialogs.map(parseNewDialog);

        prevDialog = {
          id: prevDialogs[prevDialogs.length - 1].id,
          originalIndex: activeDialog.originalIndex - 1,
        };
      } else {
        prevDialog = {
          id: dialogs[localIndex - 1].id,
          originalIndex: activeDialog.originalIndex - 1,
        };
      }

      yield put(actions.openPrevDialogToMark.success({
        prevDialogs: prevDialogs,
        activeDialog: prevDialog,
      }));
    } catch (err) {
      yield put(actions.openPrevDialogToMark.failure(new ErrorAction(err)));
    }
  });
}

function* loadProjectResolutions(): SagaIterator {
  yield takeLatest(actions.loadProjectResolutions.request, function* () {
    try {
      if (!getEnvBoolean("REACT_APP_MOCK_PROJECT_RESOLUTIONS")) {
        const response: ApiResponse<GetProjectResolutionsResponse> = yield call(apiService.getProjectResolutions);

        const resolutions = response.data.resolution_types.map<ResolutionType>(objSnakeToCamelDeep);
        yield put(actions.loadProjectResolutions.success(resolutions));
      } else {
        const resolutions = mockResolutionTypes.map<ResolutionType>(objSnakeToCamelDeep);
        yield put(actions.loadProjectResolutions.success(resolutions));
      }
    } catch (err) {
      const text = i18n.t("ERRORS.API.LOAD", { name: i18n.t("ERRORS.API.RESOLUTIONS.MULTIPLE") });
      yield put(actions.loadProjectResolutions.failure(new ErrorAction(err, text)));
    }
  });
}

function* loadQualityStatsByDay() {
  yield takeLatest(actions.loadQualityStatsByDay.request, function* (action) {
    try {
      const {
        startDate,
        endDate,
      } = action.payload;

      const request: GetQualityStatsByDayRequest = {
        query: {
          start_date: format(startOfDay(startDate), 'yyyy-MM-dd'),
          end_date: format(endOfDay(endDate), "yyyy-MM-dd"),
        },
      };

      const response: ApiResponse<GetQualityStatsByDayResponse> = yield call(
        apiService.getQualityStatsByDay,
        request,
      );

      const statistics = response.data.plot_items
        .map<StatsByDayPlotItem>(objSnakeToCamelDeep)
        .sort((a, b) => +(new Date(a.date)) - +(new Date(b.date)));

      yield put(actions.loadQualityStatsByDay.success(statistics));
    } catch (err) {
      const text = i18n.t("ERRORS.API.LOAD", { name: i18n.t("ERRORS.API.SAMPLINGS.STATISTICS") });
      yield put(actions.loadQualityStatsByDay.failure(new ErrorAction(err, text)));
    }
  });
}

function* loadQualityStatsBySamplings() {
  yield takeLatest(actions.loadQualityStatsBySamplings.request, function* (action) {
    try {
      const request: GetQualityStatsBySamplingRequest = {
        query: {
          offset: action.payload.offset,
        },
      };
      const response: ApiResponse<GetQualityStatsBySamplingResponse> = yield call(
        apiService.getQualityStatsBySamplings,
        request,
      );

      const statistics: SamplingStats[] = response.data.samplings_stats
        .map<SamplingStats>(objSnakeToCamelDeep);

      if (getEnvBoolean("REACT_APP_MOCK_PROJECT_RESOLUTIONS")) {
        statistics.forEach(s => {
          s.stats = mockResolutionTypes.map<ResolutionStatsItem>(type => ({
            resolutionTypeId: type.id,
            count: Math.floor((Math.random() * 100)),
          }));
        });
        statistics.forEach(s => {
          s.samplingSize = getTotalMarkedCount(s);

          const addSomething = Math.random() > 0.5;
          if (addSomething) {
            s.samplingSize += 10;
          }
        });
      }

      yield put(actions.loadQualityStatsBySamplings.success(statistics));
    } catch (err) {
      const text = i18n.t("ERRORS.API.LOAD", { name: i18n.t("ERRORS.API.SAMPLINGS.STATISTICS") });
      yield put(actions.loadQualityStatsBySamplings.failure(new ErrorAction(err, text)));
    }
  });
}

function* loadCurrentDialogDetails(): SagaIterator {
  yield takeLatest(actions.loadCurrentDialogDetails.request, function* () {
    try {
      const activeDialog: Dialog | undefined = yield select(getActiveDialogToMark);
      if (!activeDialog) throw new Error("No dialog");

      const request: GetChatInfoRequest = {
        query: {
          session_id: activeDialog.sessionId,
        },
      };

      const response: ApiResponse<GetChatInfoResponse> = yield call(apiService.getChatInfo, request);

      const dialogInfo = parseNewDialog(response.data);

      yield put(actions.loadCurrentDialogDetails.success(dialogInfo));
    } catch (err) {
      const text = i18n.t("ERRORS.API.DIALOGS.LOAD");
      yield put(actions.loadCurrentDialogDetails.failure(new ErrorAction(err, text)));
    }
  });
}

function* updateDialogDetails() {
  yield takeEvery([
    actions.openMarkingModal.success,
    actions.openNextDialogToMark.success,
    actions.openPrevDialogToMark.success,
  ], function* () {
    yield put(actions.loadCurrentDialogDetails.request());
  });
}

function* getNewDialogsByIds(ids: Array<Dialog['id']>) {
  const selectedProject: Project = yield select(getSelectedProject);

  const request: SearchDialogsHistoryRequest = {
    query: {},
    body: {
      chat_ids: ids,
    },
  };

  const response: ApiResponse<SearchDialogsHistoryResponse> = yield call(
    apiService.getDialogsHistoryV3,
    request,
  );

  let dialogs = response.data.dialogs.map(parseNewDialog);

  const macroses = dialogs.map(findMacrosesInNewDialog)
    .flat()
    .filter(onlyUnique);

  if (macroses.length > 0) {
    const macrosesTexts: Array<{
      macros: string,
      text: string,
    }> = yield call(getMacrosesTexts, macroses);

    dialogs = dialogs.map(dialog => fillMacrosesNewDialogs(dialog, macrosesTexts));
  }

  if (isVoiceProject(selectedProject)) {
    const checkAudioFiles: FilesState['audioFiles'] = yield select(getAudioFiles);
    if (!checkAudioFiles.wasLoaded) {
      if (!checkAudioFiles.loading) {
        yield put(filesActions.loadAudioFiles.request());
      }
      yield take(filesActions.loadAudioFiles.success);
    }

    const audioFiles: FilesState['audioFiles'] = yield select(getAudioFiles);
    dialogs = parseAudioMessagesToFullComment(dialogs, audioFiles);
  }

  return dialogs;
}

export default function* samplingsSagas(): SagaIterator {
  yield fork(loadSamplings);
  yield fork(loadNewSamplings);
  yield fork(createSampling);
  yield fork(createNewSampling);
  yield fork(deleteSampling);
  yield fork(deleteNewSampling);
  yield fork(markSampling);
  yield fork(markDialog);
  yield fork(createAutomaticSampling);
  yield fork(navigateToSamplingDialogs);
  yield fork(openMarkingModal);
  yield fork(openNextDialogToMark);
  yield fork(openPrevDialogToMark);
  yield fork(loadProjectResolutions);
  yield fork(loadQualityStatsByDay);
  yield fork(loadQualityStatsBySamplings);
  yield fork(updateDialogDetails);
  yield fork(loadCurrentDialogDetails);
}
