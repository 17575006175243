import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { block } from "bem-cn";

import { changeFeatureClarificationType, changeFeatureIsArray, Feature, FeatureClarificationType, FeatureType, getEmptyFeature, isFeatureValid } from "../../types/models/Feature";

import { ModalProps } from "../../services/modal";

import { getFeaturesCreatingStatus } from "../../redux/features/selectors";

import FeatureClarificationTypeSelector from "../../pages/features/components/featureClarificationTypeSelector";
import FeatureTypeSelector from "../../pages/features/components/featureTypeSelector";
import EntitySelector from "../../pages/features/components/entitySelector";

import SideModal, { SideModalSize } from "../../components-new/sideModal";
import Checkbox from "../../components-new/checkbox";
import TextArea from "../../components-new/textarea";
import Button from "../../components-new/button";
import Input from "../../components-new/input";

import './styles.scss';

const b = block("create-feature-modal");

export type CreateFeatureModalProps = {
  size?: SideModalSize;
  defaultSlug?: string;
  onSubmit: (data: Feature) => void,
}

function CreateFeatureModal({
  size = "normal",
  defaultSlug = '',
  onSubmit,
  close,
}: ModalProps & CreateFeatureModalProps) {
  const { t } = useTranslation();

  const creating = useSelector(getFeaturesCreatingStatus);

  const [data, setData] = useState<Feature>({
    ...getEmptyFeature(),
    slug: defaultSlug,
  });

  const handleFieldChange = useCallback(
    (_, { value, name }) => {
      setData(currentValue => ({
        ...currentValue,
        [name]: value,
      }));
    },
    [],
  );

  const handleTypeChange = useCallback(
    (newType: FeatureType) => {
      setData(currentValue => ({
        ...currentValue,
        type: newType,
      }));
    },
    [],
  );

  const handleArrayChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setData(currentValue => changeFeatureIsArray(currentValue, e.target.checked));
    },
    [],
  );

  const handleClarificationChange = useCallback(
    (value: FeatureClarificationType) => {
      setData(currentValue => changeFeatureClarificationType(
        currentValue,
        value,
      ));
    },
    [],
  );

  const handleEntityChange = useCallback(
    (newId: string) => {
      setData(currentValue => ({
        ...currentValue,
        entityId: newId,
      }));
    },
    [],
  );

  const handleExtractOrderChange = useCallback(
    (_, { value: newOrder }) => {
      setData(currentvalue => ({
        ...currentvalue,
        entityExtractOrder: Number(newOrder),
      }));
    },
    [],
  );

  const handleForceQuestionChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setData(currentvalue => ({
        ...currentvalue,
        forceQuestion: e.target.checked,
      }));
    },
    [],
  );

  const isValid = useMemo(
    () => isFeatureValid(data),
    [data],
  );

  const handleSubmit = useCallback(
    () => {
      if (!isValid) return;

      onSubmit(data);
    },
    [isValid, data, onSubmit],
  );

  return (
    <SideModal
      className={b()}
      title={(
        <div className="row-2">
          <Button
            icon="arrowLeft"
            visualStyle="ghost"
            onClick={close}
          />
          <span className="body-1 font-bold">{t("MODALS.CREATE_FEATURE.TITLE")}</span>
        </div>
      )}
      open
      size={size}
      footer={(
        <Button
          className="font-bold"
          size="large"
          fluid
          visualStyle="main"
          disabled={!isValid || creating}
          icon={creating ? "loaderBrackets" : undefined}
          onClick={handleSubmit}
        >
          {t("MODALS.CREATE_FEATURE.SAVE_AND_BACK")}
        </Button>
      )}
      onClose={close}
    >
      <div className={b("content")}>
        <Input
          className={b("slug")}
          name="slug"
          value={data.slug}
          floatingPlaceholder
          clearable
          placeholder={t("COMMON.NAME")}
          onChange={handleFieldChange}
        />
        <FeatureTypeSelector
          value={data.type}
          floatingPlaceholder
          onChange={handleTypeChange}
        />
        <Checkbox
          name="isArray"
          label={t("COMMON.ARRAY")}
          value={!!data.isArray}
          onChange={handleArrayChange}
        />
        <TextArea
          className={b("description")}
          name="description"
          placeholder={t("COMMON.DESCRIPTION")}
          value={data.description}
          rows={3}
          clearable
          onChange={handleFieldChange}
        />
        <FeatureClarificationTypeSelector
          value={data.clarificationType as FeatureClarificationType}
          floatingPlaceholder
          onChange={handleClarificationChange}
        />
        {data.clarificationType === FeatureClarificationType.fromText && (
          <>
            <div className={b("clarifying-question").mix("row-3")}>
              <Input
                value={data.clarifyingQuestion || ""}
                name="clarifyingQuestion"
                fluid
                placeholder={t("PAGE_FEATURES.CLARIFYING_QUESTION")}
                floatingPlaceholder
                clearable
                onChange={handleFieldChange}
              />
              <Checkbox
                className={b("force-question")}
                name="forceQuestion"
                label={t("PAGE_FEATURES.REQUIRED_QUESTION")}
                value={data.forceQuestion || false}
                onChange={handleForceQuestionChange}
              />
            </div>
            <EntitySelector
              value={data.entityId}
              onChange={handleEntityChange}
            />
            {!data.isArray && (
              <Input
                floatingPlaceholder
                placeholder={t("PAGE_FEATURES.EXTRACT_ORDER")}
                name="extractOrder"
                value={data.entityExtractOrder as number || 1}
                type="number"
                onChange={handleExtractOrderChange}
              />
            )}
          </>
        )}
      </div>
    </SideModal>
  );
}

export default CreateFeatureModal;
