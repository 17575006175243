import { objSnakeToCamel } from "../../services/helpers/utilities";
import { FeatureBackend } from "../backendModels/FeatureBackend";
import { Feature, FeatureClarificationType, FeatureExtractType, FeatureType } from "../models/Feature";

export function parseFeature(feature: FeatureBackend): Feature {
  return {
    ...objSnakeToCamel(feature),
  };
}

export function parseFeatureToBackend(feature: Feature): FeatureBackend {
  return {
    id: feature.id,
    slug: feature.slug,
    description: feature.description,
    domain: [],
    type: feature.type,
    is_array: !!feature.isArray,
    ...feature.clarificationType && { clarification_type: feature.clarificationType },
    ...(feature.extractRules || []).length > 0 && {
      extract_rules: feature.extractRules?.map(rule => ({ value: rule.value, pattern: rule.pattern })),
    },
    ...(feature.clarificationType === FeatureClarificationType.fromText && {
      force_question: feature.forceQuestion,
    }),
    ...(feature.clarificationType === FeatureClarificationType.fromText) && {
      clarifying_question: feature.clarifyingQuestion,
    },
    ...(feature.clarificationType === FeatureClarificationType.fromText && {
      extract_type: FeatureExtractType.default,
    }),
    ...(feature.clarificationType === FeatureClarificationType.fromText) && {
      force_question: feature.forceQuestion,
    },
    ...(feature.clarificationType === FeatureClarificationType.fromText && typeof feature.entityExtractOrder === 'number' && {
      entity_extract_order: feature.entityExtractOrder,
    }),
    ...(feature.clarificationType === FeatureClarificationType.fromText && feature.entityId && {
      entity_id: feature.entityId,
    }),
    ...(feature.defaultValue && { default_value: feature.defaultValue }),
    ...(feature.isCore && { is_core: feature.isCore }),
  };
}

export function parseFeatureValueByType(value: string | number | boolean, type: FeatureType) {
  switch (type) {
    case FeatureType.bool: {
      if (value.toString().toUpperCase() === 'TRUE') {
        return true;
      } else if (value.toString().toUpperCase() === 'FALSE') {
        return false;
      }
      return value;
    }
    case FeatureType.float: {
      return value + '';
    }
    case FeatureType.int: {
      if (isNaN(+value)) return String(value);
      return +value;
    }
    case FeatureType.str: {
      return value + '';
    }
    default: {
      return value;
    }
  }
}
